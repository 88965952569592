import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'homePage',
      component: () => import('./pages/homePage.vue')
    },
    {
      path: '/user-center',
      name: 'userPage',
      component: () => import('./pages/userPage.vue')
    },
    {
      path: '/score',
      name: 'scorePage',
      component: () => import('./pages/scorePage.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./pages/loginPage.vue')
    },
    {
      path: '/card',
      name: 'card',
      component: () => import('./pages/cardPage.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./pages/aboutPage.vue')
    },
    {
      path: '/plan',
      name: 'plan',
      component: () => import('./pages/planPage.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('./pages/newsPage.vue')
    }
  ]
});

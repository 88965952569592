import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 定义状态
  },
  mutations: {
    // 定义改变状态的方法
  },
  actions: {
    // 定义提交 mutation 的方法
  },
  modules: {
    // 如果你需要，可以在这里嵌套模块
  }
});

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'; // 导入微信jssdk
export default {
  name: 'App',
  created() {
    this.onShare();
  },
  methods: {
    onShare() {
      // 检查微信JS-SDK是否准备好
      if (typeof wx !== 'undefined') {
        this.$axios.get('/home/getJSSDK').then((res) => {
          if (res.code == 200) {
            let { jssdk, little_title, share_img, title } = res.data;
            // 配置分享信息
            wx.config({
              debug: false, // 开启调试模式，调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息可打印在页面上。
              appId:jssdk.appId,
              timestamp:jssdk.timestamp,
              nonceStr:jssdk.nonceStr,
              signature:jssdk.signature,
              jsApiList: ['onMenuShareTimeline','onMenuShareAppMessage'] // 必填，需要使用的JS接口列表
            });

            // 注册分享到朋友圈的事件
            wx.ready(function () {
              // 设置分享朋友圈的 title、desc 和 imageurl
              wx.onMenuShareTimeline({
                title: title, // 分享标题
                desc: little_title, // 分享描述
                link: window.location.href, // 分享链接
                imgUrl: share_img, // 分享图标
                success: function () {
                  // 用户确认分享后执行的回调函数
                  console.log('分享成功');
                },
                cancel: function () {
                  // 用户取消分享后执行的回调函数
                  console.log('分享取消');
                }
              });
              wx.onMenuShareAppMessage({
                title: title, // 分享标题
                desc: little_title, // 分享描述
                link: window.location.href, // 分享链接
                imgUrl: share_img, // 分享图标
                success: function () {
                  // 用户确认分享后执行的回调函数
                  console.log('分享成功');
                },
                cancel: function () {
                  // 用户取消分享后执行的回调函数
                  console.log('分享取消');
                }
              });
            });

          }
        })
        // 如果微信JS-SDK未准备好，则等待
        if (!wx.config || !wx.ready) {
          setTimeout(this.onShare, 100);
        }
      } else {
        console.error('微信JS-SDK未正确加载');
      }
    }
  }
}
</script>

<style>
/* @import './assets/css/common.css'; */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
  min-height: 100%;
  background: #f5f6fa;
}

.van-sidebar-item--select::before {
  background-color: #0281fe !important;
}
</style>

import axios from 'axios';
import router from '@/router'
const api = axios.create({
  baseURL: 'https://www.yikaoapp.cn/sike.php/single',
  // baseURL: 'http://192.168.1.99:887/sike.php/single',
});

// 请求拦截器
api.interceptors.request.use(config => {
  let token = localStorage.getItem('token');
  if(token){
    config.headers.token = token;
  }
  // 在发送请求之前做些什么
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 响应拦截器
api.interceptors.response.use(response => {
  // 对响应数据做点什么
  if(response.data.code == 401){
    console.log('token过期了');
    console.log(router);
    router.replace('/login');
  }
  return response.data;
}, error => {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default api;

import Vue from 'vue';
import App from './App.vue';
import router from './router'; // 导入 Vue Router 配置
import store from './store'; // 导入 Vuex store
import service from './service'; // 导入 axios
import Vant from 'vant'; 
import 'vant/lib/index.css'; 
Vue.use(Vant); 
Vue.prototype.$axios = service;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
